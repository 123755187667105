import React from "react";
import * as styles from "../StyledComponents";
import RightCarrouselIconsGroup from "../Images/RightCarrouselIconsGroup.svg";
import { Carousel3DSlider } from "./Carousel3DSlider";
import { CarouselImages } from "../consts";

export const HomePageThirdSection = ({ reference }) => {
  return (
    <styles.StyledThirdPartDiv ref={reference}>
      <styles.StyledInfluencersTitleDiv>
        <styles.StyledSecondPartTitleSpan style={{ color: "#333333" }}>
          Registered <styles.StyledSpace>&nbsp;</styles.StyledSpace>
        </styles.StyledSecondPartTitleSpan>
        <styles.StyledSecondPartTitleSpan
          style={{ color: "#333333", textShadow: "3px 3px 0 #FE2C55" }}
        >
          Influencers
        </styles.StyledSecondPartTitleSpan>
      </styles.StyledInfluencersTitleDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70%",
          margin: "auto",
          width: "100%",
        }}
      >
        <styles.StyledNextToSliderBubbles
          style={{ justifyContent: "end", marginTop: "180px" }}
        >
          <img
            src={RightCarrouselIconsGroup}
            style={{ transform: "scaleX(-1)" }}
          />
        </styles.StyledNextToSliderBubbles>
        <styles.StyledCarouselContainer>
          <Carousel3DSlider slides={CarouselImages} />
        </styles.StyledCarouselContainer>
        <styles.StyledNextToSliderBubbles style={{ marginTop: "120px" }}>
          <img src={RightCarrouselIconsGroup} />
        </styles.StyledNextToSliderBubbles>
      </div>
    </styles.StyledThirdPartDiv>
  );
};

import React from "react";
import { ThreeDots } from "react-loader-spinner";

export const LoadingDots = ({ style }) => {
  return (
    <div
      style={{ margin: "auto", display: "flex", ...style }}
    >
      <ThreeDots type="ThreeDots" color="teal" height={50} width={50} />
    </div>
  );
};

import styled from "styled-components";
import Rectangle from "./Images/TopRectangle.svg";
import BottomRectangle from "./Images/BottomRectangle.svg";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { FaGoogle } from "react-icons/fa";

export const StyledMainDiv = styled.div`
  zoom: 0.8;
  height: 125vh;
  flex: 1;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: content-box;
  width: 101%;
  margin-left: -12px;
  padding-left: 12px;
  @media screen and (min-width: 2000px) {
    zoom: 1;
    height: 100vh;
  }
`;

export const StyledTopOfPageDiv = styled.div`
  background-image: url(${Rectangle});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1000px;
  position: relative;
  display: flex;
  flex: 1;
  @media screen and (max-width: 1400px) {
    display: grid;
    height: 850px;
  }
  @media screen and (max-width: 1250px) {
    height: 1300px;
  }
  @media screen and (max-width: 1100px) {
    height: 1150px;
  }
  @media screen and (max-width: 532px) {
    height: 1100px;
  }
  @media screen and (max-width: 500px) {
    height: 1010px;
  }
  @media screen and (max-width: 387px) {
    height: 1030px;
  }
  @media screen and (max-width: 350px) {
    height: 990px;
  }
`;

export const StyledSecondPartDiv = styled.div`
  background-image: linear-gradient(
    to bottom,
    #e7f8f7 70%,
    rgba(0, 0, 0, 0) 30%
  );
  position: relative;
  display: flex;
  margin-bottom: 5%;
`;

export const StyledBottomDiv = styled.div`
  background-image: url(${BottomRectangle});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 50vh;
  position: relative;
  display: flex;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    min-height: 65vh;
  }
`;

export const StyledInfluencersTextDiv = styled.div`
  position: absolute;
  bottom: -37px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1250px) {
    width: 100%;
    //margin: auto;
    margin-top: 58px;
    //margin-bottom: 0;
    position: unset;
    //transform: scale(0.6);
  }
`;

export const StyledTitleDiv = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 6%;
  position: relative;
  @media screen and (max-width: 1250px) {
    width: 100%;
    margin: auto;
    display: unset;
  }
`;

export const StyledTitlePhoneContainerDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media screen and (max-width: 1250px) {
    text-align: center;
    width: unset;
    margin: auto;
    margin-top: 158px;
    width: 85%;
  }
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    width: 98vw;
  }
`;

export const StyledTitleFirstDiv = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 80%;
  position: relative;
  @media screen and (max-width: 1250px) {
    display: block;
    text-align: left;
    margin: auto;
    width: 60%;
    max-width: 60%;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
    max-width: 80%;
  }
`;

export const StyledTopSubtextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  max-width: 52%;
  @media screen and (max-width: 1250px) {
    text-align: left;
    margin: auto;
    width: 60%;
    max-width: 60%;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
    max-width: 80%;
  }
`;

export const StyledTopButtonsContainerDiv = styled.span`
  display: flex;
  margin-top: 58px;
  max-width: 80%;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    width: 65%;
    max-width: 65%;
    gap: 16px;
    margin: auto;
    margin-top: 40px;
  }
  @media screen and (max-width: 800px) {
    width: 85%;
    max-width: 85%;
  }
`;

export const StyledButtonSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: 25px;
  line-height: 30px;
  @media screen and (max-width: 350px) {
    font-size: 18px;
  }
`;

export const StyledInflencersTextImg = styled.img`
  margin-right: auto;
  margin-left: auto;
  width: 90%;
`;

export const StyledInflencersTextDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 88%;
  @media screen and (max-width: 1250px) {
    margin-bottom: 5%;
  }
`;

export const StyledTikTokLogoImg = styled.img`
  right: -50px;
  top: -50px;
  position: absolute;
  width: 300px;
`;

export const StyledSMSBubbleImg = styled.img`
  width: 95px;
  right: -97px;
  top: 34%;
  position: absolute;
  @media screen and (max-width: 700px) {
    left: -20px;
    right: unset;
    top: 0;
  }
`;

export const StyledArrowBubbleImg = styled.img`
  width: 48px;
  left: -11px;
  top: 6%;
  position: absolute;
  @media screen and (max-width: 1250px) {
    left: 65px;
    top: 33.5px;
  }
  @media screen and (max-width: 532px) {
    left: 85px;
  }
`;

export const StyledPhoneImg = styled.img`
  //width: 472px;
`;

export const StyledBoldSpan = styled.span`
  font-family: Gilroy-Bold;
  font-size: ${({ fontSize }) => fontSize || "60px"};
  line-height: 73px;
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 1500px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const StyledModalTitleSpan = styled(StyledBoldSpan)`
  margin-bottom: 10px;
  font-size: ${({ fontSize }) => fontSize || "40px"};
  line-height: 48px;
  color: black;
  white-space: nowrap;
  @media screen and (max-width: 700px) {
    font-size: 27px;
    line-height: 33px;
    white-space: unset;
  }
`;

export const StyledModalSubtitleSpan = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: black;
  @media screen and (max-width: 700px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const StyledModalTermsSpan = styled.span`
  font-size: 15px;
  line-height: 18px;
  color: black;
`;

export const StyledHowBoldSpan = styled(StyledBoldSpan)`
  font-family: Gilroy-Bold;
  font-size: ${({ fontSize }) => fontSize || "60px"};
  line-height: 73px;
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 1500px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media screen and (max-width: 550px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const StyledFirstBoldSpan = styled.span`
  font-family: Gilroy-Bold;
  font-size: ${({ fontSize }) => fontSize || "60px"};
  line-height: 73px;
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 1500px) {
    font-size: 45px;
    line-height: 50px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 30px;
    line-height: 34px;
  }
`;

export const StyledTikTokSpan = styled.span`
  font-family: Gilroy-Bold;
  font-size: ${({ fontSize }) => fontSize || "60px"};
  line-height: 73px;
  color: #ffffff;
  text-shadow: -4px 0 0 #25f4ee, 4px 0 #fe2c55;
  line-break: anywhere;
  white-space: nowrap;
  @media screen and (max-width: 1500px) {
    text-shadow: -3px 0 0 #25f4ee, 3px 0 #fe2c55;
    font-size: 45px;
    line-height: 50px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 30px;
    line-height: 34px;
  }
`;

export const StyledSecondPartTitleSpan = styled.span`
  font-family: Gilroy-Bold;
  font-size: ${({ fontSize }) => fontSize || "60px"};
  line-height: 73px;
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 1500px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media screen and (max-width: 550px) {
    display: flex;
    justify-content: center;
  }
`;

export const StyledSpace = styled.span`
  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const StyledSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: ${({ fontSize }) => fontSize || "30px"};
  line-height: ${({ lineHeight }) => lineHeight || "35px"};
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 1500px) {
    font-size: ${({ fontSize }) => fontSize || "25px"};
  }
`;

export const StyledModalLinkSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: 17px;
  line-height: 21px;
  color: black;
`;

export const StyledHowTitleSpan = styled(StyledSpan)`
  font-family: Gilroy-Medium;
  font-size: ${({ fontSize }) => fontSize || "30px"};
  line-height: ${({ lineHeight }) => lineHeight || "35px"};
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 550px) {
    font-family: Gilroy-Bold;
    font-size: 25px;
    line-height: 30px;
  }
`;

export const StyledHowSubtitleSpan = styled(StyledSpan)`
  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const StyledHowItemSpan = styled(StyledSpan)`
  @media screen and (max-width: 550px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const StyledHowUl = styled.ul`
  padding-inline-start: 0;
  margin-top: 32px;
  @media screen and (max-width: 550px) {
    margin-top: 14px;
  }
`;

export const StyledSecondSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: ${({ fontSize }) => fontSize || "30px"};
  line-height: 35px;
  color: ${({ color }) => color || "#ffffff"};
  @media screen and (max-width: 1500px) {
    font-size: 25px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const StyledEasyFirstSecondSpan = styled(StyledSecondSpan)`
  @media screen and (max-width: 1099px) {
    display: none;
  }
`;

export const StyledEasyLastSecondSpan = styled(StyledSecondSpan)`
  @media screen and (min-width: 1100px) {
    display: none;
  }
`;

export const StyledCopyrightSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  @media screen and (max-width: 1250px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyledAllRightsSpan = styled(StyledCopyrightSpan)`
  position: absolute;
  bottom: 20%;
  width: 100%;
  z-index: 12;
  justify-content: center;
  @media screen and (max-width: 1250px) {
    position: unset;
    margin-bottom: 64px;
    margin-top: 32px;
  }
`;

export const StyledAllRightsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  width: 40%;
  justify-content: center;
  @media screen and (max-width: 1250px) {
    width: unset;
  }
`;

export const StyledHowDoesItWorkDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(19, 125, 122, 0.3);
  border-radius: 20px;
  //padding: 64px;
  width: 63%;
  height: 58%;
  padding: 64px 50px;
  margin: auto;
  margin-top: 141px;
  @media screen and (max-width: 1250px) {
    box-shadow: 0px 4px 20px rgba(19, 125, 122, 0.3);
    margin-top: 70px;
  }
`;

export const StyledListContainerDiv = styled.div`
  display: flex;
  margin: auto;
  margin-top: 50px;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    margin-top: 12px;
  }
`;

export const StyledListDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  border-right: ${({ borderRight }) => borderRight};
  margin-left: ${({ marginLeft }) => marginLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  @media screen and (max-width: 1250px) {
    width: 100%;
    border-bottom: ${({ borderRight }) => borderRight};
    border-right: unset;
    margin-left: unset;
    padding-right: unset;
    margin-top: ${({ marginTop }) => marginTop};
  }
`;

export const StyledCampaignButton = styled.button`
  padding: 16px 25px;
  background-color: #25f4ee;
  border-radius: 10px;
  margin-right: 16px;
  height: 62px;
  cursor: pointer;
  width: 400px;
  min-width: 300px;
  z-index: 2;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  @media screen and (max-width: 350px) {
    min-width: 250px;
  }
`;

export const StyledInfluencerButton = styled.button`
  background-color: transparent;
  padding: 16px 25px;
  color: #fe2c55;
  border: 2px solid #fe2c55;
  box-sizing: border-box;
  border-radius: 10px;
  height: 62px;
  cursor: pointer;
  width: 400px;
  min-width: 300px;
  z-index: 2;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  @media screen and (max-width: 350px) {
    min-width: 250px;
  }
`;

export const StyledCampaignListDiv = styled.div`
  display: flex;
  width: 90%;
  height: 550px;
  margin: auto;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    height: unset;
  }
`;

export const StyledThirdPartDiv = styled.div`
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media screen and (max-width: 500px) {
    width: 98vw;
  }
`;

export const StyledCarouselContainer = styled.div`
  width: 70%;
  height: 700px;
  margin: auto;
  @media screen and (max-width: 550px) {
    height: 500px;
  }
`;

export const StyledSeeMoreButton = styled.button`
  height: 74px;
  width: 423px;
  max-width: 90vw;
  background: #000000;
  border-radius: 10px;
  margin: 0 auto 32px auto;
`;

export const StyledPhoneDiv = styled.div`
  margin: auto;
`;

export const StyledPhoneContainerDiv = styled.div`
  display: flex;
  position: absolute;
  z-index: 12;
  top: 270px;
  right: 15%;
  @media screen and (max-width: 1400px) {
    transform: scale(0.9);
    top: 164px;
    right: 10%;
  }
  @media screen and (max-width: 1250px) {
    top: 543px;
    right: 20%;
  }
  @media screen and (max-width: 1100px) {
    transform: scale(0.8);
    top: 436px;
  }
  @media screen and (max-width: 800px) {
    right: 10%;
  }
  @media screen and (max-width: 700px) {
    right: 5%;
  }
  @media screen and (max-width: 532px) {
    transform: scale(0.7);
    top: 429px;
    right: 0%;
  }
  @media screen and (max-width: 500px) {
    transform: scale(0.6);
    top: 383px;
  }
  @media screen and (max-width: 450px) {
    right: -4%;
  }
  @media screen and (max-width: 430px) {
    right: -8%;
  }
  @media screen and (max-width: 410px) {
    right: -10%;
  }
  @media screen and (max-width: 400px) {
    top: 383px;
    right: -13%;
  }
  @media screen and (max-width: 390px) {
    right: -15%;
  }
  @media screen and (max-width: 387px) {
    top: 403px;
  }
  @media screen and (max-width: 350px) {
    transform: scale(0.5);
    top: 406px;
    right: -28%;
  }
`;

export const StyledInfluencersTitleDiv = styled.div`
  display: flex;
  margin: 5% auto 32px auto;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const StyledNextToSliderBubbles = styled.div`
  display: flex;
  flex-direction: column;
  width: 11%;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const StyledDialogImageDiv = styled.div`
  width: 40%;
  height: auto;
  display: flex;
  background-color: #e7f8f7;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const StyledInfluencerLogoImage = styled.img`
  width: 820px;
  @media screen and (max-width: 1250px) {
    width: 80%;
    margin: auto;
    display: flex;
  }
`;

export const StyledContactUsContainerDiv = styled.div`
  position: absolute;
  right: 10%;
  height: 100%;
  zindex: 12;
  display: flex;
  @media screen and (max-width: 1250px) {
    position: unset;
    margin-bottom: 32px;
  }
`;

export const StyledContactUsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  height: 25%;
  @media screen and (max-width: 1250px) {
    margin: auto;
    height: 80px;
    text-align: center;
    margin-top: auto;
    margin-bottom: 32px;
    gap: 8px;
  }
`;

export const StyledSocialMediaIconsContainerSmall = styled.div`
  display: none;
  @media screen and (max-width: 1250px) {
    margin-bottom: 32px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const StyledSocialMediaIconsContainerBig = styled.div`
  position: absolute;
  top: 115px;
  left: 115px;
  display: flex;
  gap: 8px;
  align-items: center;
  z-index: 12;
  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const StyledPopupInput = styled.input`
  margin-bottom: 25px;
  height: 32px;
  border: none;
  border-bottom: black solid 1px;
  outline: none;
  font-size: 18px;
  width: 100%;
`;

export const StyledPopupLink = styled.a`
  color: #1a73e8;
  cursor: pointer;
  font-weight: bold;
  font-family: Gilroy-Medium;
  font-size: 17px;
  line-height: 21px;
`;

export const StyledPopupRightSide = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 50%;
  align-items: ${({ alignItems }) => alignItems ?? "unset"};
  @media screen and (max-width: 900px) {
    width: 90%;
  }
`;

export const StyledModalButtonSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: 25px;
  line-height: 35px;
  color: white;
  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
`;

export const StyledResetPasswordImg = styled.img`
  width: 77%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
`;

export const StyledDialogDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: inherit;
`;

export const StyledCampaignDoubleDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1250px) {
    flex-direction: row;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const StyledInputTooltip = styled(Tooltip)`
  position: absolute;
  top: 10px;
  right: 5px;
  color: red;
`;

export const StyledModalRightSuccess = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    align-items: center;
  }
`;

export const StyledSignUpButton = styled.button`
  heigth: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #000000;
  border-radius: 10px;
  z-index: 2;
`;

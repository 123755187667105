import PopCampaignImg1 from "./Images/PopCampaignImg1.jpg";
import PopCampaignImg2 from "./Images/PopCampaignImg2.jpg";
import PopCampaignImg3 from "./Images/PopCampaignImg3.jpg";
import PopCampaignImg4 from "./Images/PopCampaignImg4.jpg";
import AvatarIcon1 from "./Images/avatarIcon1.svg";
import AvatarIcon2 from "./Images/avatarIcon2.svg";
import AvatarIcon3 from "./Images/avatarIcon3.svg";
import AvatarIcon4 from "./Images/avatarIcon4.svg";
import Influencer1 from "./Images/InfluencerIphone1.png"
import Influencer2 from "./Images/InfluencerIphone2.png";
import Influencer3 from "./Images/InfluencerIphone3.png";
import Influencer4 from "./Images/InfluencerIphone4.png";
import Influencer5 from "./Images/InfluencerIphone5.png";
import Influencer6 from "./Images/InfluencerIphone6.png";
import Influencer7 from "./Images/InfluencerIphone7.png";

export const popularCampaigns = [
  {
    avatarIcon: AvatarIcon1,
    mainImage: PopCampaignImg1,
    description:
      "F E E L I N G O O D is our mission. High vibration sustainable clothing brand",
    title: "feelingood.studio",
    budget: "$2,500",
    chatsAmount: "8",
  },
  {
    avatarIcon: AvatarIcon2,
    mainImage: PopCampaignImg2,
    description:
      "A fashion brand specializing in designing handbags and wallets clothing brand",
    title: "Kantero-Bags",
    budget: "$2,500",
    chatsAmount: "10",
  },
  {
    avatarIcon: AvatarIcon3,
    mainImage: PopCampaignImg3,
    description:
      "The Certificate in Happiness Studies is a year-long online academic course",
    title: "The certificate in happiness studies",
    budget: "$10,000",
    chatsAmount: "4",
  },
  {
    avatarIcon: AvatarIcon4,
    mainImage: PopCampaignImg4,
    description: "Co.Co is a crowd-funding sustainable & social fashion brand",
    title: "Co.Co",
    budget: "$6,000",
    chatsAmount: "6",
  },
];

export const howDoesItWorkItems = [
  {
    title: "Marketer",
    subTitle: "As a Marketer, you can:",
    items: [
      "Create campaigns free of charge and find Influencers.",
      "Receive creative ideas and proposals from Influencers.",
      "Choose the Influencers that align best with your campaign's goals.",
      "Complete payment safely and securely only once the work is completed.",
    ],
    style: { borderRight: "4px solid #E5E5E5", paddingRight: "80px" },
  },
  {
    title: "Influencer",
    subTitle: "As a Influencer, you can:",
    items: [
      "Browse multiple campaigns posted by Marketers.",
      "Filter campaigns based on your preferences.",
      "Apply for campaigns that you're inteerested in.",
      "Complete the campaign and receive payment quickly and securely",
    ],
    style: { marginLeft: "98px", marginTop: "40px" },
  },
];

export const CarouselImages = [
  {
    src: Influencer1,
    alt: "Influencer 1",
    id: 1,
  },
  {
    src: Influencer2,
    alt: "Influencer 2",
    id: 2,
  },
  {
    src: Influencer3,
    alt: "Influencer 3",
    id: 3,
  },
  {
    src: Influencer4,
    alt: "Influencer 4",
    id: 4,
  },
  {
    src: Influencer5,
    alt: "Influencer 5",
    id: 5,
  },
  {
    src: Influencer6,
    alt: "Influencer 6",
    id: 6,
  },
  {
    src: Influencer7,
    alt: "Influencer 7",
    id: 7,
  },
  {
    src: Influencer1,
    alt: "Influencer 8",
    id: 8,
  },
  {
    src: Influencer2,
    alt: "Influencer 9",
    id: 9,
  },
  {
    src: Influencer3,
    alt: "Influencer 10",
    id: 10,
  },
  {
    src: Influencer4,
    alt: "Influencer 11",
    id: 11,
  },
  {
    src: Influencer5,
    alt: "Influencer 12",
    id: 12,
  },
  {
    src: Influencer6,
    alt: "Influencer 13",
    id: 13,
  },
  {
    src: Influencer7,
    alt: "Influencer 14",
    id: 14,
  }
];

export const LOGIN = "login";
export const FORGOT_PASSWORD = "forgotPassword";
export const SIGN_UP = "signUp";

export const MARKETER = "marketer";
export const INFLUENCER = "influencer";

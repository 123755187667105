import React from "react";
import * as styles from "../StyledComponents";
import { howDoesItWorkItems } from "../consts";
import { FaCheck } from "react-icons/fa";

export const HomePageSecondSection = ({ reference }) => {
  return (
    <styles.StyledSecondPartDiv ref={reference}>
      <styles.StyledHowDoesItWorkDiv>
        <styles.StyledHowBoldSpan style={{ color: "#333333" }}>
          How does it{" "}
          {
            <styles.StyledHowBoldSpan
              style={{ color: "#333333", textShadow: "-3px 0 0 #25F4EE" }}
            >
              work
            </styles.StyledHowBoldSpan>
          }
          ?
        </styles.StyledHowBoldSpan>
        <styles.StyledListContainerDiv>
          {howDoesItWorkItems.map((type, i) => (
            <styles.StyledListDiv {...type.style} key={i}>
              <styles.StyledHowTitleSpan
                color="black"
                fontSize="40px"
                lineHeight="48px"
              >
                {type?.title}
              </styles.StyledHowTitleSpan>
              <styles.StyledHowSubtitleSpan
                color="black"
                fontSize="28px"
                lineHeight="33px"
              >
                {type?.subTitle}
              </styles.StyledHowSubtitleSpan>
              <styles.StyledHowUl>
                {type?.items?.map((item, i) => (
                  <li
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                      marginBottom: "28px",
                    }}
                    key={i}
                  >
                    <FaCheck
                      style={{
                        backgroundColor: "#25F4EE",
                        color: "white",
                        borderRadius: "100%",
                        minWidth: "15px",
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <styles.StyledHowItemSpan fontSize="22px" color="black">
                      {item}
                    </styles.StyledHowItemSpan>
                  </li>
                ))}
              </styles.StyledHowUl>
            </styles.StyledListDiv>
          ))}
        </styles.StyledListContainerDiv>
      </styles.StyledHowDoesItWorkDiv>
    </styles.StyledSecondPartDiv>
  );
};

import React, { useState } from "react";
import * as styles from "../StyledComponents";
import InfluencersLogo from "../Images/INFLUENCERS.svg";
import InflunecersTextPic from "../Images/INFLUENCERS.png";
import facebookIcon from "../Images/facebook-social.svg";
import instagramIcon from "../Images/instagram-social.svg";
import tiktokIcon from "../Images/tiktok-social.svg";
import { FaCopyright } from "react-icons/fa";
import { StyledAllRightsDiv } from "../StyledComponents";
import { Dialog } from "@material-ui/core";

export const HomePageBottomSection = ({ showInfo }) => {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  const redirectToPolicy = () => {
    window.open("https://influencers.com/pages/privacy-policy.html");
  };

  const redirectToSocialMedia = (type) => {
    let link = "";
    switch (type) {
      case "facebook": {
        link = "https://www.facebook.com/Influencers.com1/";
        break;
      }
      case "instagram": {
        link = "https://www.instagram.com/influencers.com_official/";
        break;
      }
      case "tiktok": {
        link = "https://www.tiktok.com/@influencers.com1";
        break;
      }
    }

    if (link) window.open(link);
  }

  const handleDialogClose = () => {
    setIsContactDialogOpen(false);
  };
  return (
    <>
      <styles.StyledBottomDiv>
        <styles.StyledInfluencersTextDiv style={{ bottom: "10%" }}>
          <styles.StyledInflencersTextDiv>
            <styles.StyledInfluencerLogoImage src={InfluencersLogo} />
          </styles.StyledInflencersTextDiv>
          <styles.StyledInflencersTextImg src={InflunecersTextPic} />
          <styles.StyledSocialMediaIconsContainerBig>
            <img src={tiktokIcon} onClick={() => redirectToSocialMedia("tiktok")} style={{cursor: "pointer"}}/>
            <img src={instagramIcon} onClick={() => redirectToSocialMedia("instagram")} style={{cursor: "pointer"}} />
            <img src={facebookIcon} onClick={() => redirectToSocialMedia("facebook")} style={{cursor: "pointer"}} />
          </styles.StyledSocialMediaIconsContainerBig>
        </styles.StyledInfluencersTextDiv>
        <styles.StyledContactUsContainerDiv>
          <styles.StyledContactUsDiv>
            <styles.StyledSocialMediaIconsContainerSmall>
              <img src={tiktokIcon} onClick={() => redirectToSocialMedia("tiktok")} style={{cursor: "pointer"}}/>
              <img src={instagramIcon} onClick={() => redirectToSocialMedia("instagram")} style={{cursor: "pointer"}}/>
              <img src={facebookIcon} onClick={() => redirectToSocialMedia("facebook")} style={{cursor: "pointer"}}/>
            </styles.StyledSocialMediaIconsContainerSmall>
            <styles.StyledCopyrightSpan
              onClick={() => setIsContactDialogOpen(true)}
              style={{ cursor: "pointer" }}
            >
              CONTACT US
            </styles.StyledCopyrightSpan>
            <styles.StyledCopyrightSpan
              style={{ cursor: "pointer" }}
              onClick={redirectToPolicy}
            >
              PRIVACY POLICY
            </styles.StyledCopyrightSpan>
            <styles.StyledCopyrightSpan
              style={{ cursor: "pointer" }}
              onClick={redirectToPolicy}
            >
              TERMS & CONDITIONS
            </styles.StyledCopyrightSpan>
          </styles.StyledContactUsDiv>
        </styles.StyledContactUsContainerDiv>
        <styles.StyledAllRightsSpan>
          <StyledAllRightsDiv>
            <FaCopyright />
            &nbsp; All Rights reserved to influencers 2021
          </StyledAllRightsDiv>
        </styles.StyledAllRightsSpan>
      </styles.StyledBottomDiv>
      {isContactDialogOpen && (
        <Dialog
          onClose={handleDialogClose}
          open={isContactDialogOpen}
          className="contact-modal"
        >
          <styles.StyledSpan color="black" style={{ textAlign: "center" }}>
            Contact our costumer support at:{" "}
          </styles.StyledSpan>
          <styles.StyledSpan
            onClick={() => {
              navigator.clipboard.writeText("accountservices@influencers.com");
              handleDialogClose();
              showInfo("Email copied!");
            }}
            color="#1a73e8"
            style={{
              cursor: "pointer",
              textAlign: "center",
              wordBreak: "break-word",
            }}
          >
            accountservices@influencers.com
          </styles.StyledSpan>
        </Dialog>
      )}
    </>
  );
};

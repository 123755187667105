import axios from "axios";
import { influencersUrl, MARKETER_USER, url } from "../consts/user.const";

export const redirectByUserType = ({ userType }) => {
  if (!userType) return;
  if (userType === MARKETER_USER) {
    return (window.location.href = `${influencersUrl}/pages/campaigns.html`);
  }
  window.location.href = `${influencersUrl}/pages/myCampaigns.html`;
};

export const login = async ({ email, password }) => {
  try {
    const response = await axios.post(`${url}/users/login`, {
      email,
      password,
    });
    const user = response?.data?.data;
    if (response?.data?.error)
      return { error: response.data.errorMsg ?? "An error occured.Please try again" };
    localStorage.setItem("token", user?.token);
    localStorage.setItem("user_name", user?.user_name);
    return { user };
  } catch (e) {}
};

export const resetPassword = async ({ email }) => {
  const response = await axios.post(`${url}/users/me/forgotPassword`, {
    email,
  });
  return response?.data;
};

export const signUp = async ({ email, password, userName: name, type }) => {
  try {
    await axios.post(`${url}/users`, {
      email,
      password,
      name,
      type,
    });
  } catch (e) {
    throw new Error(e.response?.data?.errorMsg ?? "An error occurred. Please try again")
  }
};

export const validateToken = async () => {
  const token = localStorage.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const user = await axios.get(`${url}/users/me`, config);
  return user?.data?.data?.user;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re?.test(String(email)?.toLowerCase()?.trim());
};

export const validatePassword = (password) => {
  return password?.trim()?.length >= 6 && password?.trim()?.length < 16;
};

export const validateUserName = (userName) => {
  return userName && userName?.trim()?.length <= 20;
};

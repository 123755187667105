import React, { useEffect, useState } from "react";
import * as styles from "../StyledComponents";
import LoginPopupPic from "../Images/LoginPopupPic.svg";
import { FORGOT_PASSWORD, SIGN_UP } from "../consts";
import {
  login,
  redirectByUserType,
  validateEmail,
  validatePassword,
} from "../services/login.service";
import { isEmpty } from "lodash";
import { LoadingDots } from "./LoadingDots";
import { renderInfoIcon } from "../services/tooltip.service";

export const Login = ({ handleDialogClose, handlePopupClick, showError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState({
    email: false,
    password: false,
  });

  const handleUserKeyPress = (event) => {
    const { key } = event;
    if (key === "Enter" && !isButtonDisabled()) {
      handleLogin();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  const isButtonDisabled = () => {
    return !isEmailValid || !email || !isPasswordValid || !password;
  };

  const toggleTooltip = (inputField, toOpen) => {
    if (!inputField) return;
    setIsTooltipOpen((prev) => ({
      ...prev,
      [inputField]: toOpen,
    }));
  };

  const handleEmailChange = (e) => {
    const emailVal = e?.target?.value;
    setEmail(emailVal);
    if (!validateEmail(emailVal)) {
      return setIsEmailValid(false);
    }
    toggleTooltip("email", false);
    setIsEmailValid(true);
  };

  const handlePasswordChange = (e) => {
    const passwordVal = e?.target?.value;
    setPassword(passwordVal);
    if (!validatePassword(passwordVal)) {
      return setIsPasswordValid(false);
    }
    toggleTooltip("password", false);
    setIsPasswordValid(true);
  };

  const handleLogin = async () => {
    try {
      if (!validateEmail(email)) {
        return setIsEmailValid(false);
      }
      setIsEmailValid(true);
      if (!validatePassword(password)) {
        return setIsPasswordValid(false);
      }
      setIsPasswordValid(true);
      setIsLoading(true);
      const { error, user } = await login({ email, password });
      if (error || isEmpty(user)) return showError(error);
      redirectByUserType({ userType: user.user_type });
      handleDialogClose();
    } catch (e) {
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <styles.StyledDialogImageDiv>
        <img
          src={LoginPopupPic}
          style={{ width: "70%", height: "65%", margin: "auto" }}
        />
      </styles.StyledDialogImageDiv>
      <styles.StyledPopupRightSide>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <>
            <styles.StyledModalTitleSpan>
              Welcome Back
            </styles.StyledModalTitleSpan>
            <div
              style={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <styles.StyledPopupInput
                  value={email}
                  onChange={handleEmailChange}
                  className="login-input"
                  type="email"
                  placeholder="E-mail"
                  style={{
                    borderBottom:
                      !isEmailValid && email?.length !== 0
                        ? "red solid 2px"
                        : "black solid 1px",
                  }}
                />
                {!isEmailValid &&
                  email?.length !== 0 &&
                  renderInfoIcon({
                    field: "email",
                    tooltipText: "Please enter a valid e-mail address",
                    toggleTooltip,
                    isTooltipOpen,
                  })}
              </div>
              <div
                style={{
                  position: "relative",
                }}
              >
                <styles.StyledPopupInput
                  value={password}
                  onChange={handlePasswordChange}
                  className="login-input"
                  type="password"
                  placeholder="Password"
                  style={{
                    marginBottom: "20px",
                    borderBottom:
                      !isPasswordValid && password?.length !== 0
                        ? "red solid 2px"
                        : "black solid 1px",
                  }}
                />
                {!isPasswordValid &&
                  password?.length !== 0 &&
                  renderInfoIcon({
                    field: "password",
                    tooltipText:
                      "Please enter a valid password (6-16 characters)",
                    toggleTooltip,
                    isTooltipOpen,
                  })}
              </div>
            </div>
            <button
              style={{
                heigth: "52px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "25px",
                backgroundColor: "#000000",
                borderRadius: "10px",
                opacity: isButtonDisabled() ? 0.5 : 1,
                cursor: isButtonDisabled() ? "not-allowed" : "pointer",
              }}
              disabled={isButtonDisabled()}
              onClick={handleLogin}
            >
              <styles.StyledModalButtonSpan>Login</styles.StyledModalButtonSpan>
            </button>
            <styles.StyledPopupLink
              onClick={() => handlePopupClick({ type: FORGOT_PASSWORD })}
              style={{ marginBottom: 8 }}
            >
              Forgot your password?{" "}
            </styles.StyledPopupLink>
            <styles.StyledModalLinkSpan>
              Don't have an Account?{" "}
              <styles.StyledPopupLink
                onClick={() => handlePopupClick({ type: SIGN_UP })}
                style={{}}
              >
                Sign Up
              </styles.StyledPopupLink>
            </styles.StyledModalLinkSpan>
          </>
        )}
      </styles.StyledPopupRightSide>
    </>
  );
};

import SignUpPopupPic from "../Images/SignUpPopupPic.svg";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import {
  CheckCircleOutlined,
  RadioButtonUncheckedOutlined,
} from "@material-ui/icons";
import { INFLUENCER, LOGIN, MARKETER } from "../consts";
import React, { useEffect, useState } from "react";
import * as styles from "../StyledComponents";
import {
  signUp,
  validateEmail,
  validatePassword,
  validateUserName,
} from "../services/login.service";
import { LoadingDots } from "./LoadingDots";
import { GmailLink } from "./GmailLink";
import { renderInfoIcon } from "../services/tooltip.service";
import { MARKETER_USER } from "../consts/user.const";

export const SignUp = ({ handlePopupClick, initialAccountType, showError }) => {
  const [didAgree, setDidAgree] = useState(false);
  const [accountType, setAccountType] = useState(initialAccountType);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isUserNameValid, setIsUserNameValid] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState({
    userName: false,
    email: false,
    password: false,
  });

  const handleUserKeyPress = (event) => {
    const { key } = event;
    if (key === "Enter" && !isFormValid()) {
      handleSignUp();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  const isFormValid = () => {
    return (
      !userName ||
      !isUserNameValid ||
      !email ||
      !isEmailValid ||
      !password ||
      !isPasswordValid ||
      !didAgree ||
      !accountType
    );
  };

  const handleEmailChange = (e) => {
    const emailVal = e?.target?.value;
    setEmail(emailVal);
    if (!validateEmail(emailVal)) {
      return setIsEmailValid(false);
    }
    toggleTooltip("email", false);
    setIsEmailValid(true);
  };

  const handlePasswordChange = (e) => {
    const passwordVal = e?.target?.value;
    setPassword(passwordVal);
    if (!validatePassword(passwordVal)) {
      return setIsPasswordValid(false);
    }
    toggleTooltip("password", false);
    setIsPasswordValid(true);
  };

  const handleUserNameChange = (e) => {
    const userNameVal = e?.target?.value;
    setUserName(userNameVal);
    if (!validateUserName(userNameVal)) {
      return setIsUserNameValid(false);
    }
    toggleTooltip("userName", false);
    setIsUserNameValid(true);
  };

  const handleSignUp = async () => {
    try {
      if (!isFormValid) return;
      setIsLoading(true);
      await signUp({
        email,
        password,
        userName,
        type: accountType === MARKETER_USER ? 1 : 2,
      });
      setIsSuccess(true);
      window.gtag('event', 'conversion', {'send_to': 'AW-345979287/AZ8cCOurjckCEJfz_KQB'});
      window.fbq('track', 'CompleteRegistration');
    } catch (e) {
      showError(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleTooltip = (inputField, toOpen) => {
    if (!inputField) return;
    setIsTooltipOpen((prev) => ({
      ...prev,
      [inputField]: toOpen,
    }));
  };

  return (
    <>
      <styles.StyledDialogImageDiv>
        <img
          src={SignUpPopupPic}
          style={{ width: "70%", height: "65%", margin: "auto" }}
        />
      </styles.StyledDialogImageDiv>
      <styles.StyledPopupRightSide>
        {isLoading ? (
          <LoadingDots />
        ) : !isSuccess ? (
          <>
            <styles.StyledModalTitleSpan>
              Join Influencers.com
            </styles.StyledModalTitleSpan>
            <RadioGroup row>
              <FormControlLabel
                value="Marketer"
                control={<Radio />}
                checked={accountType === MARKETER}
                onChange={() => setAccountType(MARKETER)}
                label={
                  <styles.StyledModalSubtitleSpan>
                    Marketer
                  </styles.StyledModalSubtitleSpan>
                }
              />
              <FormControlLabel
                value="Influencer"
                control={<Radio />}
                checked={accountType === INFLUENCER}
                onChange={() => setAccountType(INFLUENCER)}
                label={
                  <styles.StyledModalSubtitleSpan>
                    Influencer
                  </styles.StyledModalSubtitleSpan>
                }
              />
            </RadioGroup>
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ position: "relative" }}>
                <styles.StyledPopupInput
                  value={userName}
                  onChange={handleUserNameChange}
                  className="login-input"
                  type="text"
                  placeholder="User Name"
                  style={{
                    borderBottom:
                      !isUserNameValid && userName?.length !== 0
                        ? "red solid 2px"
                        : "black solid 1px",
                  }}
                />
                {!isUserNameValid &&
                  userName?.length !== 0 &&
                  renderInfoIcon({
                    field: "userName",
                    tooltipText: "Please enter a valid user name (too long)",
                    toggleTooltip,
                    isTooltipOpen,
                  })}
              </div>
              <div style={{ position: "relative" }}>
                <styles.StyledPopupInput
                  value={email}
                  onChange={handleEmailChange}
                  className="login-input"
                  type="email"
                  placeholder="E-mail"
                  style={{
                    borderBottom:
                      !isEmailValid && email?.length !== 0
                        ? "red solid 2px"
                        : "black solid 1px",
                  }}
                />
                {!isEmailValid &&
                  email?.length !== 0 &&
                  renderInfoIcon({
                    field: "email",
                    tooltipText: "Please enter a valid e-mail address",
                    toggleTooltip,
                    isTooltipOpen,
                  })}
              </div>
              <div style={{ position: "relative" }}>
                <styles.StyledPopupInput
                  value={password}
                  onChange={handlePasswordChange}
                  className="login-input"
                  type="password"
                  placeholder="Password"
                  style={{
                    marginBottom: "5px",
                    borderBottom:
                      !isPasswordValid && password?.length !== 0
                        ? "red solid 2px"
                        : "black solid 1px",
                  }}
                />
                {!isPasswordValid &&
                  password?.length !== 0 &&
                  renderInfoIcon({
                    field: "password",
                    tooltipText:
                      "Please enter a valid password (6-16 characters)",
                    toggleTooltip,
                    isTooltipOpen,
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "-10px",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Checkbox
                icon={
                  <RadioButtonUncheckedOutlined style={{ color: "#25F4EE" }} />
                }
                checkedIcon={
                  <CheckCircleOutlined style={{ color: "#25F4EE" }} />
                }
                checked={didAgree}
                onChange={() => {
                  setDidAgree((prev) => !prev);
                }}
              />
              <styles.StyledModalTermsSpan>
                I have read & agree to the{" "}
                <styles.StyledModalTermsSpan
                  style={{
                    borderBottom: "1px solid black",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      "https://influencers.com/pages/privacy-policy.html"
                    )
                  }
                >
                  Terms and Conditions
                </styles.StyledModalTermsSpan>
              </styles.StyledModalTermsSpan>
            </div>
            <styles.StyledSignUpButton
              disabled={isFormValid()}
              style={{
                opacity: isFormValid() ? 0.5 : 1,
                cursor: isFormValid() ? "not-allowed" : "pointer",
              }}
              onClick={handleSignUp}
            >
              <styles.StyledModalButtonSpan>
                Join Now
              </styles.StyledModalButtonSpan>
            </styles.StyledSignUpButton>
            <styles.StyledModalLinkSpan>
              Already have an account?{" "}
              <styles.StyledPopupLink
                onClick={() => handlePopupClick({ type: LOGIN })}
              >
                Login
              </styles.StyledPopupLink>
            </styles.StyledModalLinkSpan>
          </>
        ) : (
          <styles.StyledModalRightSuccess>
            <styles.StyledModalTitleSpan>
              Email Confirmation
            </styles.StyledModalTitleSpan>
            <styles.StyledModalSubtitleSpan>
              Yay, We're almost there! We just need you to confirm. Please check
              your inbox and verify your email address.
            </styles.StyledModalSubtitleSpan>
            <GmailLink />
          </styles.StyledModalRightSuccess>
        )}
      </styles.StyledPopupRightSide>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SignUp } from "./SignUp";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { HomePageFirstSection } from "./HomePageFirstSection";
import { HomePageSecondSection } from "./HomePageSecondSection";
import { HomePageThirdSection } from "./HomePageThirdSection";
import { HomePageFourthSection } from "./HomePageFourthSection";
import { HomePageBottomSection } from "./HomePageBottomSection";
import * as styles from "../StyledComponents";
import { FORGOT_PASSWORD, LOGIN, SIGN_UP } from "../consts";
import { useParams } from "react-router-dom";
import { redirectByUserType, validateToken } from "../services/login.service";
import { isEmpty } from "lodash";
import { LoadingDots } from "./LoadingDots";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Introduction = () => {
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [loginDialogType, setLoginDialogType] = useState(LOGIN);
  const [accountType, setAccountType] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const mainRef = useRef();
  const howItworksRef = useRef();
  const influencersRef = useRef();
  const campaignsRef = useRef();
  const { type } = useParams();

  useEffect(() => {
    validateUser();
    if (type === "login=1") {
      setLoginDialogOpen(true);
    } else if (type) {
      return (window.location.href = "/");
    }
  }, []);

  const scrollTo = (page) => {
    switch (page) {
      case 0: {
        mainRef.current?.scrollIntoView();
        break;
      }
      case 1: {
        howItworksRef.current?.scrollIntoView();
        break;
      }
      case 2: {
        influencersRef.current?.scrollIntoView();
        break;
      }
      case 3: {
        campaignsRef.current?.scrollIntoView();
        break;
      }
      default: {
        mainRef.current?.scrollIntoView();
        break;
      }
    }
  };

  const validateUser = async () => {
    try {
      setIsloading(true);
      const user = await validateToken();
      if (isEmpty(user)) return;
      redirectByUserType({ userType: user.type });
    } catch (e) {
    } finally {
      setIsloading(false);
    }
  };

  const notifyError = (error) => {
    toast.error(error ?? "An error occurred. Please try again", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyInfo = (msg) => {
    if (!msg) return;
    toast.info(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDialogClose = () => {
    setLoginDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setLoginDialogOpen(true);
    mainRef.current.scrollTo(0, 0);
  };

  const handlePopupClick = ({ type, account }) => {
    setAccountType(account);
    setLoginDialogType(type);
    handleDialogOpen();
  };

  const renderDialogContent = () => {
    switch (loginDialogType) {
      case LOGIN: {
        return (
          <Login
            handleDialogClose={handleDialogClose}
            handlePopupClick={handlePopupClick}
            showError={notifyError}
          />
        );
      }
      case SIGN_UP: {
        return (
          <SignUp
            handlePopupClick={handlePopupClick}
            initialAccountType={accountType}
            showError={notifyError}
          />
        );
      }
      case FORGOT_PASSWORD: {
        return (
          <ForgotPassword
            handleDialogClose={handleDialogClose}
            handlePopupClick={handlePopupClick}
            showError={notifyError}
          />
        );
      }
      default:
        break;
    }
  };

  return (
    <>
      <styles.StyledMainDiv ref={mainRef}>
        {isLoading ? (
          <LoadingDots
            style={{ position: "absolute", top: "48%", left: "48%" }}
          />
        ) : (
          <>
            <HomePageFirstSection
              handlePopupClick={handlePopupClick}
              scrollTo={scrollTo}
            />
            <HomePageSecondSection reference={howItworksRef} />
            <HomePageThirdSection reference={influencersRef} />
            <HomePageFourthSection
              handlePopupClick={handlePopupClick}
              reference={campaignsRef}
            />
            <HomePageBottomSection showInfo={notifyInfo} />
            <Dialog
              onClose={handleDialogClose}
              open={loginDialogOpen}
              className="user-modal"
            >
              <styles.StyledDialogDiv>
                {renderDialogContent()}
              </styles.StyledDialogDiv>
            </Dialog>
          </>
        )}
      </styles.StyledMainDiv>
      <ToastContainer />
    </>
  );
};

export default Introduction;

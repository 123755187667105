import React from "react";
import * as styles from "../StyledComponents";
import GmailIcon from "../Images/GmailIcon.png";

export const GmailLink = () => {
  return (
    <styles.StyledSpan
      fontSize="16px"
      color="black"
      onClick={() => window.open("https://mail.google.com/mail")}
      style={{ marginTop: "8px", cursor: "pointer" }}
    >
      Open in{" "}
      <styles.StyledSpan fontSize="16px" color="#1a73e8">
        Gmail{" "}
      </styles.StyledSpan>
      <img src={GmailIcon} style={{ width: "20px" }} />
    </styles.StyledSpan>
  );
};

import React, { useEffect, useState } from "react";
import * as styles from "../StyledComponents";
import ForgotPasswordPopupPic from "../Images/ForgotPasswordPopupPic.svg";
import { StyledPopupRightSide } from "../StyledComponents";
import { LOGIN } from "../consts";
import { FaInfoCircle } from "react-icons/fa";
import { resetPassword, validateEmail } from "../services/login.service";
import { LoadingDots } from "./LoadingDots";
import { GmailLink } from "./GmailLink";
import { renderInfoIcon } from "../services/tooltip.service";

export const ForgotPassword = ({ showError, handlePopupClick }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState({
    email: false,
  });

  const handleUserKeyPress = (event) => {
    const { key } = event;
    if (key === "Enter" && !isButtonDisabled()) {
      handleReset();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  });

  const toggleTooltip = (inputField, toOpen) => {
    if (!inputField) return;
    setIsTooltipOpen((prev) => ({
      ...prev,
      [inputField]: toOpen,
    }));
  };

  const isButtonDisabled = () => {
    return !isEmailValid;
  };

  const handleEmailChange = (e) => {
    const emailVal = e?.target?.value;
    setEmail(emailVal);
    if (!validateEmail(emailVal)) {
      return setIsEmailValid(false);
    }
    toggleTooltip("email", false);
    setIsEmailValid(true);
  };

  const handleReset = async () => {
    try {
      if (!isEmailValid) return;
      setIsLoading(true);
      const res = await resetPassword({ email });
      if (res.error) {
        showError(res.error);
        return;
      }
      setIsSuccess(true);
    } catch (e) {
      showError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <styles.StyledDialogImageDiv>
        <styles.StyledResetPasswordImg src={ForgotPasswordPopupPic} />
      </styles.StyledDialogImageDiv>
      <StyledPopupRightSide alignItems={isSuccess ? "center" : "unset"}>
        {isLoading ? (
          <LoadingDots />
        ) : !isSuccess ? (
          <>
            <styles.StyledModalTitleSpan>
              Forgot your password?
            </styles.StyledModalTitleSpan>
            <styles.StyledModalSubtitleSpan>
              Don't worry! Just fill in your email and we'll send you a link to
              reset your password
            </styles.StyledModalSubtitleSpan>
            <div
              style={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ position: "relative" }}>
                <styles.StyledPopupInput
                  value={email}
                  onChange={handleEmailChange}
                  className="login-input"
                  type="email"
                  placeholder="E-mail"
                  style={{
                    borderBottom:
                      !isEmailValid && email?.length !== 0
                        ? "red solid 2px"
                        : "black solid 1px",
                  }}
                />
                {!isEmailValid &&
                  email?.length !== 0 &&
                  renderInfoIcon({
                    field: "email",
                    tooltipText: "Please enter a valid email address",
                    toggleTooltip,
                    isTooltipOpen,
                  })}
              </div>
            </div>
            <button
              style={{
                heigth: "52px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "25px",
                backgroundColor: "#000000",
                borderRadius: "10px",
                opacity: isButtonDisabled() ? 0.5 : 1,
                cursor: isButtonDisabled() ? "not-allowed" : "pointer",
              }}
              disabled={isButtonDisabled()}
              onClick={handleReset}
            >
              <styles.StyledModalButtonSpan>
                Reset Password
              </styles.StyledModalButtonSpan>
            </button>
            <styles.StyledModalLinkSpan>
              Remember your credentials?{" "}
              <styles.StyledPopupLink
                onClick={() => handlePopupClick({ type: LOGIN })}
              >
                Login
              </styles.StyledPopupLink>
            </styles.StyledModalLinkSpan>
          </>
        ) : (
          <styles.StyledModalRightSuccess>
            <styles.StyledModalTitleSpan>
              An Email Has Been Sent
            </styles.StyledModalTitleSpan>
            <styles.StyledModalSubtitleSpan>
              {`If the email you entered exists, a password reset link will be sent to ${email}`}
            </styles.StyledModalSubtitleSpan>
            <GmailLink />
          </styles.StyledModalRightSuccess>
        )}
      </StyledPopupRightSide>
    </>
  );
};

import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { Favorite } from "@material-ui/icons";
import styled from "styled-components";
import ChatsIcon from "../Images/ChatsIcon.svg";
import { popularCampaigns } from "../consts";

const StyledSpan = styled.span`
  font-family: Gilroy-Medium;
  font-size: ${({ fontSize }) => fontSize || "23px"};
  line-height: ${({ lineHeight }) => lineHeight || "32px"};
  color: black;
  @media screen and (max-width: 1550px) {
    font-size: ${({ fontSize }) => fontSize || "18px"};
    line-height: ${({ lineHeight }) => lineHeight || "26px"};
  }
`;

const StyledCardActions = styled(CardActions)`
  border-top: 1px solid black;
  height: 64px;
  position: absolute;
  bottom: 0;
  width: 100%;
  @media screen and (max-width: 1250px) {
    position: unset;
  }
`;

const StyledCampaignCard = styled(Card)`
  width: 25%;
  background-color: unset;
  height: inherit;
  position: relative;
  max-width: 420px;
  margin-left: ${({ index }) => (index !== 0 ? "16px" : "auto")};
  margin-right: ${({ index }) =>
    index === popularCampaigns.length - 1 ? "auto" : "0"};
  @media screen and (max-width: 1550px) {
    width: 100%;
    margin-bottom: 32px;
  }
  @media screen and (max-width: 1250px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CampaignCard = (props) => {
  const {
    avatarIcon,
    mainImage,
    description,
    title,
    budget,
    chatsAmount,
    index,
  } = props;
  const [clicked, setClicked] = useState(false);

  return (
    <StyledCampaignCard
      sx={{
        maxWidth: 345,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      index={index}
    >
      <CardMedia component="img" height="194" image={mainImage} />
      <CardHeader
        style={{ width: "85%" }}
        avatar={
          <Avatar aria-label="recipe" style={{ width: 56, height: 56 }}>
            <img src={avatarIcon} style={{ width: "-webkit-fill-available" }} />
          </Avatar>
        }
        title={
          <StyledSpan
            className="campaign-card-header"
            style={{ fontFamily: "Gilroy-Bold" }}
          >
            {title}
          </StyledSpan>
        }
      />
      <CardContent style={{ marginTop: "auto" }}>
        <StyledSpan>{description}</StyledSpan>
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "16px" }}
        >
          <img src={ChatsIcon} style={{ marginRight: "8px" }} />
          <StyledSpan style={{ color: "#B6B7BB" }}>
            {chatsAmount}&nbsp;Chats
          </StyledSpan>
        </div>
      </CardContent>
      <StyledCardActions disableSpacing>
        <IconButton onClick={() => setClicked((prev) => !prev)}>
          <Favorite
            style={{ fontSize: "40px", color: clicked ? "#FE2C55" : "#B5B6BA" }}
          />
        </IconButton>
        <div
          style={{
            marginLeft: "auto",
            marginRight: 24,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledSpan style={{ fontSize: "20px", textAlign: "end" }}>
            Budget
          </StyledSpan>
          <StyledSpan style={{ fontSize: "30px" }}>{budget}</StyledSpan>
        </div>
      </StyledCardActions>
    </StyledCampaignCard>
  );
};

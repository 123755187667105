import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import Introduction from "./components/Introduction";

function App() {
  return (
    <Router>
      <Routes>
        <Route default path="/" element={<Introduction />} />
        <Route path="/:type" element={<Introduction />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;

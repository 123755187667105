import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import styled from "styled-components";

export const StyledImage = styled.img`
  width: 350px;
  margin: auto;
  @media screen and (max-width: 550px) {
    width: 250px;
  }
`;

export const Carousel3DSlider = ({ slides }) => {
  const [goToSlide, setGoToSlide] = useState(null);
  const [carouselSlides, setCarouselSlides] = useState(slides);
  const carouselParentRef = useRef();

  useEffect(() => {
    if (slides?.length)
      setCarouselSlides(
        slides.map((element, index) => {
          return {
            key: element?.id,
            content: (
              <StyledImage
                key={element?.id}
                src={element?.src}
                alt={element?.alt}
              />
            ),
            onClick: () => {
              setGoToSlide(index);
            },
          };
        })
      );
  }, [slides]);

  return (
    <div
      style={{ width: "66%", height: "100%", margin: "auto" }}
      ref={carouselParentRef}
    >
      <Carousel
        offsetRadius={4}
        goToSlide={goToSlide}
        slides={carouselSlides}
        showNavigation={false}
      />
    </div>
  );
};

import React from "react";
import styled from "styled-components";
import UserIcon from "./../Images/UserIcon.svg";
import InfluencersLogo from "./../Images/INFLUENCERS.svg";
import { LOGIN, SIGN_UP } from "../consts";

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  z-index: 12;
  margin: auto;
  width: 100%;
`;

export const StyledMainNavDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  z-index: 12;
  position: absolute;
  height: 82px;
  top: 28px;
  width: 100%;
`;

export const NavLink = styled.div`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  white-space: nowrap;
  &.active {
    color: white;
  }
`;

export const User = styled.img`
  display: none;
  @media screen and (max-width: 1400px) {
    display: flex;
    position: absolute;
    right: 50px;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  width: 88%;
  margin-right: -24px;
  margin: auto;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const NavDiv = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin-right: -24px;
  justify-content: space-between;
  margin: auto;
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

export const JoinNowButton = styled.button`
  width: 185px;
  background: #000000;
  border-radius: 10px;
  color: white;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px;
  font-family: Gilroy-Bold;
  font-size: 25px;
  line-height: 30px;
  box-shadow: none;
  @media screen and (max-width: 1550px) {
    font-size: 20px;
  }
`;

export const LoginButton = styled.button`
  width: 185px;
  background: #000000;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13px;
  font-family: Gilroy-Bold;
  font-size: 25px;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  font-family: Gilroy-Bold;
  font-size: 25px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
  @media screen and (max-width: 1550px) {
    font-size: 20px;
  }
`;

const NavButtonsDiv = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  margin-left: auto;
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const StyledInfluencersDiv = styled.div`
  @media screen and (max-width: 700px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 600px) {
    transform: scale(0.8);
  }
  @media screen and (max-width: 500px) {
    transform: scale(0.7);
  }
  @media screen and (max-width: 400px) {
    transform: scale(0.6);
    margin-left: -20px;
  }
  @media screen and (max-width: 350px) {
    transform: scale(0.5);
    margin-left: -45px;
  }
`;

const Navbar = (props) => {
  const { handlePopupClick, scrollTo } = props;

  return (
    <>
      <StyledMainNavDiv>
        <Nav>
          <NavMenu>
            <div style={{ display: "flex", width: "30%", alignItems: "start" }}>
              <StyledInfluencersDiv>
                <img src={InfluencersLogo} />
              </StyledInfluencersDiv>
            </div>
            <NavDiv>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <NavLink onClick={() => scrollTo(1)}>
                  <StyledSpan>How it works</StyledSpan>
                </NavLink>
                <NavLink onClick={() => scrollTo(2)}>
                  <StyledSpan>Our Influencers</StyledSpan>
                </NavLink>
                <NavLink onClick={() => scrollTo(3)}>
                  <StyledSpan>Our Campaigns</StyledSpan>
                </NavLink>
              </div>
            </NavDiv>
            <NavButtonsDiv>
              <NavLink
                onClick={() => {
                  handlePopupClick({ type: LOGIN });
                  scrollTo(0);
                }}
                style={{ marginLeft: "auto", marginRight: "7%" }}
              >
                <StyledSpan>Login</StyledSpan>
              </NavLink>
              <JoinNowButton
                onClick={() => handlePopupClick({ type: SIGN_UP })}
              >
                Join Now
              </JoinNowButton>
            </NavButtonsDiv>
          </NavMenu>
          <User
            src={UserIcon}
            onClick={() => handlePopupClick({ type: LOGIN })}
          />
        </Nav>
      </StyledMainNavDiv>
    </>
  );
};

export default Navbar;

import React from "react";
import Navbar from "./Navbar";
import * as styles from "../StyledComponents";
import TikTokLogoBehind from "../Images/TikTokLogoBehind.svg";
import { INFLUENCER, MARKETER, SIGN_UP } from "../consts";
import ArrowBubble from "../Images/ArrowBubble.svg";
import SMSBubble from "../Images/SMSBubble.svg";
import RealisticSmartphonePic from "../Images/RealisticSmartphone.svg";
import InflunecersTextPic from "../Images/INFLUENCERS.png";

export const HomePageFirstSection = ({ handlePopupClick, scrollTo, ref }) => {
  return (
    <styles.StyledTopOfPageDiv id="Top" ref={ref}>
      <Navbar
        scrollTo={scrollTo}
        handlePopupClick={handlePopupClick}
        style={{ position: "absolute" }}
      />
      <styles.StyledTitlePhoneContainerDiv>
        <styles.StyledTitleDiv>
          <styles.StyledTitleFirstDiv>
            <styles.StyledTikTokLogoImg src={TikTokLogoBehind} />
            <styles.StyledFirstBoldSpan>
              Find&nbsp;
              <styles.StyledTikTokSpan>TikTok&nbsp;</styles.StyledTikTokSpan>
              Influencers for your marketing campaigns
            </styles.StyledFirstBoldSpan>
          </styles.StyledTitleFirstDiv>
          <styles.StyledTopSubtextDiv>
            <styles.StyledSecondSpan>
              It's easy! Post your campaign free of charge & receive creative
              ideas from Influencers.
            </styles.StyledSecondSpan>
          </styles.StyledTopSubtextDiv>
          <styles.StyledTopButtonsContainerDiv>
            <styles.StyledCampaignButton
              onClick={() =>
                handlePopupClick({ type: SIGN_UP, account: MARKETER })
              }
            >
              <styles.StyledButtonSpan>
                Create a Campaign
              </styles.StyledButtonSpan>
            </styles.StyledCampaignButton>
            <styles.StyledInfluencerButton
              style={{ color: "#FE2C55" }}
              onClick={() =>
                handlePopupClick({ type: SIGN_UP, account: INFLUENCER })
              }
            >
              <styles.StyledButtonSpan>
                I'm an Influencer
              </styles.StyledButtonSpan>
            </styles.StyledInfluencerButton>
          </styles.StyledTopButtonsContainerDiv>
        </styles.StyledTitleDiv>
        <styles.StyledPhoneContainerDiv id="phone-div">
          <styles.StyledPhoneDiv>
            <styles.StyledArrowBubbleImg src={ArrowBubble} />
            <styles.StyledSMSBubbleImg src={SMSBubble} />
            <styles.StyledPhoneImg
              src={RealisticSmartphonePic}
            ></styles.StyledPhoneImg>
          </styles.StyledPhoneDiv>
        </styles.StyledPhoneContainerDiv>
      </styles.StyledTitlePhoneContainerDiv>
      <styles.StyledInfluencersTextDiv>
        <styles.StyledInflencersTextImg src={InflunecersTextPic} />
      </styles.StyledInfluencersTextDiv>
    </styles.StyledTopOfPageDiv>
  );
};

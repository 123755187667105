import { ClickAwayListener } from "@material-ui/core";
import * as styles from "../StyledComponents";
import { FaInfoCircle } from "react-icons/fa";
import React from "react";

export const renderInfoIcon = ({
  field,
  tooltipText,
  toggleTooltip,
  isTooltipOpen,
}) => {
  return (
    <ClickAwayListener onClickAway={() => toggleTooltip(field, false)}>
      <styles.StyledInputTooltip
        open={isTooltipOpen[field]}
        title={
          <styles.StyledSpan fontSize="16px">{tooltipText}</styles.StyledSpan>
        }
        placement="left"
      >
        <div>
          <FaInfoCircle
            onMouseOver={() => toggleTooltip(field, true)}
            onMouseOut={() => toggleTooltip(field, false)}
            onClick={() => toggleTooltip(field, true)}
          />
        </div>
      </styles.StyledInputTooltip>
    </ClickAwayListener>
  );
};

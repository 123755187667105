import React from "react";
import * as styles from "../StyledComponents";
import {LOGIN, popularCampaigns, SIGN_UP} from "../consts";
import { CampaignCard } from "./CampaignCard";

export const HomePageFourthSection = ({ handlePopupClick, reference }) => {
  return (
    <styles.StyledThirdPartDiv ref={reference}>
      <styles.StyledInfluencersTitleDiv>
        <styles.StyledSecondPartTitleSpan style={{ color: "#333333" }}>
          Popular <styles.StyledSpace>&nbsp;</styles.StyledSpace>
        </styles.StyledSecondPartTitleSpan>
        <styles.StyledSecondPartTitleSpan
          style={{ color: "#333333", textShadow: "-3px -3px 0 #25F4EE" }}
        >
          Campaigns
        </styles.StyledSecondPartTitleSpan>
      </styles.StyledInfluencersTitleDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "65vh",
        }}
      >
        <styles.StyledCampaignListDiv>
          {popularCampaigns.map(
            (
              {
                title,
                mainImage,
                budget,
                avatarIcon,
                description,
                chatsAmount,
              },
              index
            ) => (
              <CampaignCard
                key={index}
                index={index}
                avatarIcon={avatarIcon}
                title={title}
                budget={budget}
                mainImage={mainImage}
                description={description}
                chatsAmount={chatsAmount}
              />
            )
          )}
        </styles.StyledCampaignListDiv>
        <styles.StyledSeeMoreButton
          onClick={() => handlePopupClick({ type: SIGN_UP })}
        >
          <styles.StyledSpan style={{ cursor: "pointer" }}>
            Sign up to See more
          </styles.StyledSpan>
        </styles.StyledSeeMoreButton>
      </div>
    </styles.StyledThirdPartDiv>
  );
};
